<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form @submit="saveMission" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Tên nhiệm vụ(*):"
                label-for="input-1"
                description="Vd : Liên kết tài khoản ngân hàng"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  required
                  placeholder="Tên nhiệm vụ"
                  max="255"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Loại nhiệm vụ (*):"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.code"
                  :options="mission_type_option"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                label="Trạng thái:"
                label-for="input-4"
              >
                <b-form-checkbox
                  id="input-4"
                  v-model="form.status"
                  value="1"
                ></b-form-checkbox>
              </b-form-group>

              <b-button type="submit" variant="primary">{{
                is_edit ? "Cập nhập" : "Xác nhận"
              }}</b-button>
              <router-link v-if="is_edit" :to="'/wallet-run/mission'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <b-button v-else type="reset" variant="danger">Làm mới</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormMission",
  mixins: [Common],
  data() {
    return {
      form: {
        name: "",
        code: "",
        status: 1,
      },
      mission_type_option: [{ text: "Chọn loại nhiệm vụ", value: null }],
      show: true,
      is_edit: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí nhiệm vụ ", route: "/wallet-run/mission" },
      { title: "Thêm nhiệm vụ" },
    ]);
  },
  methods: {
    async loadMission(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletRunRepository.getMission(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.form.name = body.data.name;
          this.form.code = body.data.code;
          this.form.status = body.data.status;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCodes() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletRunRepository.getOptions();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.options = Object.keys(body.data);
          let that = this;
          this.options.forEach((item) => {
            that.mission_type_option.push(item);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveMission: async function () {
      if (this.is_edit) {
        return this.updateMission();
      }
      this.$bus.$emit("show-loading", true);
      let status = !this.form.status ? "0" : "1";
      let params = {
        name: this.form.name,
        code: this.form.code,
        status: status,
      };
      WalletRunRepository.createMission(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "mission" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    updateMission: async function () {
      this.$bus.$emit("show-loading", true);
      let status = !this.form.status ? "0" : "1";
      let params = {
        name: this.form.name,
        code: this.form.code,
        status: status,
      };
      WalletRunRepository.updateMission(this.$route.params.id, params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "mission" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form.name = "";
      this.form.code = "";
      this.form.status = 1;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
  created() {
    this.getCodes();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadMission(this.$route.params.id);
    }
  },
};
</script>

<style scoped></style>
